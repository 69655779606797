<template>
  <svg width="1600px" height="900px" viewBox="0 0 1600 900" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <path
        d="M861.600005,205.200001 L745.480469,425.163086 C724.259766,459.180664 714.513346,489.901367 716.241211,517.325195 L716.241211,812.865234"
        id="path-1"
        class="path-line"
      />
      <!-- <filter
        x="-8.5%"
        y="-3.8%"
        width="117.5%"
        height="107.4%"
        filterUnits="objectBoundingBox"
        id="filter-2"
      >
        <feMorphology
          radius="3.00000012"
          operator="dilate"
          in="SourceAlpha"
          result="shadowSpreadOuter1"
        />
        <feOffset dx="0" dy="0" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feMorphology radius="3.00000012" operator="erode" in="SourceAlpha" result="shadowInner" />
        <feOffset dx="0" dy="0" in="shadowInner" result="shadowInner" />
        <feComposite
          in="shadowOffsetOuter1"
          in2="shadowInner"
          operator="out"
          result="shadowOffsetOuter1"
        />
        <feGaussianBlur stdDeviation="5.5" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 1 0"
          type="matrix"
          in="shadowBlurOuter1"
        />
      </filter> -->
      <radialGradient
        cx="50%"
        cy="50%"
        fx="50%"
        fy="50%"
        r="50%"
        gradientTransform="translate(0.500000,0.500000),rotate(90.000000),scale(1.000000,0.980555),translate(-0.500000,-0.500000)"
        id="radialGradient-3"
      >
        <stop stop-color="#FFFFFF" stop-opacity="0" offset="0%" />
        <stop stop-color="#FFFFFF" stop-opacity="0.466182255" offset="100%" />
      </radialGradient>
    </defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="path-strip">
        <g id="Route" stroke-linecap="round" stroke-linejoin="round">
            <use fill="black" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-1"></use>
            <use stroke="#FFFFFF" stroke-width="6.00000024" xlink:href="#path-1"></use>
        </g>
        <g id="Route" stroke-linecap="round" stroke-linejoin="round">
            <use fill="black" fill-opacity="1" filter="url(#filter-4)" xlink:href="#path-3"></use>
            <use stroke="#FFFFFF" stroke-width="6.00000024" xlink:href="#path-3"></use>
        </g>
        <g id="Ending-Point" transform="translate(617.800004, 733.000000)">
            <circle id="Oval-back" fill="url(#radialGradient-5)" cx="14.4000006" cy="14.6000011" r="14.4000006"></circle>
            <circle id="Oval-back" fill="url(#radialGradient-5)" cx="14.4000006" cy="14.6000011" r="9.60000038"></circle>
            <circle id="Oval" fill="#5A5A5A" cx="14.4000006" cy="14.6000011" r="4.80000019"></circle>
        </g>
        <g id="Starting-Point" transform="translate(847.800004, 190.000000)">
            <circle id="Oval-back" fill="url(#radialGradient-5)" cx="14.4000006" cy="14.6000011" r="14.4000006"></circle>
            <circle id="Oval-back" fill="url(#radialGradient-5)" cx="14.4000006" cy="14.6000011" r="9.60000038"></circle>
            <circle id="Oval" fill="#5A5A5A" cx="14.4000006" cy="14.6000011" r="4.80000019"></circle>
        </g>
        <g id="Starting-Point" transform="translate(702.000000, 797.000000)">
            <circle id="Oval-back" fill="url(#radialGradient-5)" cx="14.4000006" cy="14.6000011" r="14.4000006"></circle>
            <circle id="Oval-back" fill="url(#radialGradient-5)" cx="14.4000006" cy="14.6000011" r="9.60000038"></circle>
            <circle id="Oval" fill="#5A5A5A" cx="14.4000006" cy="14.6000011" r="4.80000019"></circle>
        </g>
        <text id="The-Las-Vegas-Strip" font-family="ArtegraSans-Regular, Artegra Sans" font-size="14.4000006" font-weight="normal" line-spacing="15.6000006" fill="#FFFFFF">
            <tspan x="887" y="201">The Las</tspan>
            <tspan x="887" y="216.600001">Vegas Strip</tspan>
            <tspan x="887" y="232.200001">(North End)</tspan>
        </text>
        <text id="The-Las-Vegas-Strip" font-family="ArtegraSans-Regular, Artegra Sans" font-size="14.4000006" font-weight="normal" line-spacing="15.6000006" fill="#FFFFFF">
            <tspan x="741.199996" y="808">The Las</tspan>
            <tspan x="741.199996" y="823.600001">Vegas Strip</tspan>
            <tspan x="741.199996" y="839.200001">(South End)</tspan>
        </text>
        <image id="Bitmap" x="492" y="683" width="157" height="141" href="@/assets/images/icons/end.png"></image>
        <rect id="Rectangle" fill="#000000" x="1599" y="899" width="1" height="1"></rect>
        <rect id="Rectangle" fill="#000000" x="0" y="0" width="1" height="1"></rect>
        <rect id="Rectangle" fill="#000000" x="1599" y="0" width="1" height="1"></rect>
        <rect id="Rectangle" fill="#000000" x="0" y="899" width="1" height="1"></rect>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "BellagioPath",
  mounted() {
    var path = document.querySelector(".path-line");
    var length = path.getTotalLength();
    path.style.transition = path.style.WebkitTransition = "none";
    path.style.strokeDasharray = length + " " + length;
    path.style.strokeDashoffset = length;
    path.getBoundingClientRect();
    path.style.transition = path.style.WebkitTransition =
      "stroke-dashoffset 2s ease-in-out";
    path.style.strokeDashoffset = "0";

    // var pathGlow = document.querySelector(".path-glow");
    // var lengthGlow = pathGlow.getTotalLength();
    // pathGlow.style.transition = pathGlow.style.WebkitTransition = "none";
    // pathGlow.style.strokeDasharray = lengthGlow + " " + lengthGlow;
    // pathGlow.style.strokeDashoffset = lengthGlow;
    // pathGlow.getBoundingClientRect();
    // pathGlow.style.transition = pathGlow.style.WebkitTransition =
    //   "stroke-dashoffset 2s ease-in-out";
    // pathGlow.style.strokeDashoffset = "0";
  },
};
</script>

<style lang="scss" scoped>
</style>